import React, { useState, useReducer, useEffect, useRef, useCallback } from "react";
import { Badge, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { deletePostAction, getPostsAction, updatePostAction } from "../../../store/actions/PostActions";
import moment from "moment";
import Swal from "sweetalert2";
export const Activities = () => {
  const dispatch = useDispatch();
  const [activities, setActivities] = useState([]);
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentActivities = activities.slice(startIndex, startIndex + itemsPerPage);
  const [tarif, setTarif] = useState({});

  useEffect(() => {
    fetchActivities()
  }, [])

  const fetchActivities = async (plat = null) => {
    try {
      const posts = await dispatch(getPostsAction('/tarif/index', {
      }))
      if (posts) {
        setActivities(posts)
        setTarif(posts.reduce((acc, item) => {
          acc[item.id] = item.tarif;
          return acc;
        }, {}))
      }
    } catch (error) {
    }
  }
  useEffect(() => {
    fetchActivities();
  }, [])
  const typeKendaraan = (type) => {
    switch (type) {
      case 'motor':
        return 'Motor'
      case 'rimotor':
        return 'Rawat Inap Motor'
      case 'mobil':
        return 'Mobil'
      case 'rimobil':
        return 'Rawat Inap Mobil'
    }
  }
  const submitActivity = async (e, id) => {
    e.preventDefault();
    const data = {
      tarif : tarif[id]
    }
    const response = await dispatch(updatePostAction('/tarif/update', data, id))
    if (response) {
      fetchActivities();
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Data Tersimpan",
        showConfirmButton: false,
        timer: 1500
      });
    }
  }
  const handleTarifChange = (e, id) => {
    const newTarif = e.target.value;
    setTarif((prevState) => ({
      ...prevState,
      [id]: newTarif,
    }));
  };
  return (
    <>
      <Table responsive bordered>
        <thead>
          <tr>
            <th>No</th>
            <th>Jenis Kendaraan</th>
            <th>Tarif</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {activities.map((item, key) => (
            <tr key={key}>
              <th>{key + 1}</th>
              <td className="align-top">
                {typeKendaraan(item.type)}
              </td>
              <td className="align-top">
                <input className="form-control" value={tarif[item.id]} onChange={(e) => handleTarifChange(e, item.id)} />
              </td>
              <td className="align-top">
                <form onSubmit={(e) => {
                  e.preventDefault();
                  submitActivity(e, item.id, tarif[item.id]);
                }}>
                  <button className="btn btn-success" type="submit">Simpan</button>
                </form>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="table-pagenation">
        <p className="ms-0">
          Showing <span>{startIndex + 1}-{Math.min(startIndex + currentActivities.length, activities.length)}</span>
          from <span>{activities.length}</span> data
        </p>
      </div>
    </>
  );
};
export default Activities;
