import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import loadable from "@loadable/component";
// import { Link } from 'react-router-dom';
import { Row, Col, Card, Dropdown } from "react-bootstrap";

import pMinDelay from "p-min-delay";

import PageTitle from "../../layouts/PageTitle";
import DropDownBlog from "./DropDownBlog";
import ApexCharts from 'apexcharts';
import HomeSlider from "../Dashboard/HomeSlider";
import { getPostsAction } from "../../../store/actions/PostActions";
import { useDispatch } from "react-redux";
const ProjectAreaChart = loadable(() =>
  pMinDelay(import("./Banking/ProjectAreaChart"), 1000)
);

function ApexChart() {
  const [startDate, setStartDate] = useState(new Date());
  const [selectBtn, setSelectBtn] = useState("This Month");
  const [selectBtn2, setSelectBtn2] = useState("This Month");
  const dispatch = useDispatch();
  const [pageData, setPageData] = useState({});
  const [hisotyModal, setHisoryModal] = useState(false);
  const fetchData = async () => {
      try {
          const posts = await dispatch(getPostsAction('/kendaraan/data'))
          if (posts) {
              setPageData(posts[0])
          }
      } catch (error) {
      }
  }
  useEffect(() => {
      fetchData()
  }, [])

  const handleSeries = (value) => {
    //alert('dd');
    ApexCharts.exec('assetDistribution', 'toggleSeries', value)
  }
  // const handleSeries2 = () => {
  //   //alert('dd');
  //   ApexCharts.exec('assetDistribution', 'toggleSeries', 'Expense')
  // }

  const projectSeries = (value) => {
    //alert('dd');
    ApexCharts.exec('assetDistribution2', 'toggleSeries', value)
  }
  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="row">
          <div className="col-xl-12">
            <div className="page-titles style1">
              <div className="align-items-center">
                <h2 className="heading">Monitoring Alat</h2>
                <span>Rekap data aktivitas parkir</span>
              </div>
            </div>
          </div>
          <HomeSlider />
          <div className="col-xl-8" >
            <div className="card crypto-chart pb-4">
              <div className="card-header pb-0 border-0 flex-wrap">
                <div className="mb-2 mb-sm-0">
                  <div className="chart-title mb-3">
                  </div>
                  <div className="d-flex align-items-center mb-3 mb-sm-0">
                    <div className="round weekly" id="dzOldSeries">
                      <div>
                        <input type="checkbox" id="checkbox1"
                          value="Persent" onClick={() => projectSeries('Persent')}
                          name="radio"
                        />
                        <label htmlFor="checkbox1" className="checkmark"></label>
                      </div>
                      <div>
                        <span className="fs-14">Bulan Ini</span>
                        <h4 className="fs-5 font-w600 mb-0">Rp.{(pageData.motor * 2000) + (pageData.mobil * 3000)}</h4>
                      </div>
                    </div>
                    <div className="round " id="dzNewSeries">
                      <div>
                        <input type="checkbox" id="checkbox" name="radio"
                          value="Visitors" onClick={() => projectSeries('Visitors')}
                        />
                        <label htmlFor="checkbox" className="checkmark"></label>
                      </div>
                      <div>
                        <span className="fs-14">Minggu Ini</span>
                        <h4 className="fs-5 font-w600 mb-0">Rp.{(pageData.motor * 2000) + (pageData.mobil * 3000)}</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-static">
                  <div className="d-flex align-items-center mb-3 ">
                    <Dropdown className="me-4 drop-select">
                      <Dropdown.Toggle as="div" className="i-false drop-select-btn">{selectBtn2} <i className="fa-solid fa-angle-down"></i></Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => setSelectBtn2("This Month")}>Bulan Ini</Dropdown.Item>
                        <Dropdown.Item onClick={() => setSelectBtn2("This Weeks")}>Minggu Ini</Dropdown.Item>
                        <Dropdown.Item onClick={() => setSelectBtn2("Today")}>Hari Ini</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <DropDownBlog />
                  </div>
                  <div className="progress-content">
                    <div className="d-flex justify-content-between">
                      <h6>Total</h6>
                      <span className="pull-end">Rp.{(pageData.motor * 2000) + (pageData.mobil * 3000)}</span>
                    </div>
                    <div className="progress mt-1">
                      <div className="progress-bar bg-primary" style={{ width: "70%", height: "100%" }} role="progressbar">
                        <span className="sr-only">60% Complete</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body pt-2 custome-tooltip pb-0">
                {/* <div id="activity"></div> */}
                <ProjectAreaChart />
              </div>
            </div>
          </div>
          <div className="col-xl-4">
            <div className="card">
              <div className="card-header pb-0 border-0 flex-wrap">
                <div className="mb-2 mb-sm-0">
                  <div className="chart-title mb-3">
                    <h2 className="heading">Kondisi Alat</h2>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Keterangan Alat</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Printer</th>
                      <th className="d-flex gap-2"><span className="bg-success rounded-circle text-white" style={{
                        width: '20px',
                        height: '20px',
                        display: 'inline-flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '50%',
                        backgroundColor: '#28a745',
                        color: 'white'
                      }}><i class="fa-solid fa-check "></i></span>Baik</th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApexChart;
