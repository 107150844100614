import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Modal } from "react-bootstrap";
import { createPostAction, createPostFileAction, updatePostAction } from "../../../store/actions/PostActions";
import { useDispatch } from "react-redux";

const BasicModal = forwardRef((props, ref) => {
  const [modalBox, setModalBox] = useState(false);
  const dispatch = useDispatch();
  const [type, setType] = useState('');
  const [plat, setPlat] = useState('');
  const [editId, setEditId] = useState('');
  const [errorPlat, setErrorPlat] = useState(false);
  const [errorType, setErrorType] = useState(false);
  const [tarif, setTarif] = useState(0);

  useImperativeHandle(ref, () => ({
    openModal() {
      setModalBox(true);
    },
    openEdit(item) {
      setModalBox(true);
      setType(item.type)
      setPlat(item.plat)
      setEditId(item.id)
    }
  }));
  const submitActivity = async (e) => {
    e.preventDefault();
    let hasError = false;
    if (!type) {
      setErrorType(true);
      hasError = true;
    }
    if (!plat) {
      setErrorPlat(true);
      hasError = true;
    }
    if (hasError) return;
    const data = {
      type: type,
      plat: plat,
      tarif: tarif,
    }
    let response = null;
    if (editId) {
      response = await dispatch(updatePostAction('/kendaraan/update', data, editId))
    } else {
      response = await dispatch(createPostAction('/kendaraan/store', data))
    }
    if (response) {
      if (!editId) {
        props.onPrintClick(response.data)
      }
      props.fetchActivities()
      setModalBox(false);
      resetInput();
    }
  }
  const resetInput = () => {
    setType('')
    setPlat('')
    setEditId('');
    setTarif(0);
  }

  const handleType = (e, type) => {
    e.preventDefault();
    setType(type)
    switch (type) {
      case 'Motor':
        setTarif(props.tarifList['motor'])
        break;
      case 'Rawat Inap Motor':
        setTarif(props.tarifList['rimotor'])
        break;
      case 'Mobil':
        setTarif(props.tarifList['mobil'])
        break;
      case 'Rawat Inap Mobil':
        setTarif(props.tarifList['rimobil'])
        break;
    }
  }
  return (
    <>
      <Modal
        show={modalBox}
        onHide={setModalBox}
        className="fade bd-example-modal-lg"
        size="lg"
      >
        <>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Isi Data Kendaraan</h5>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                onClick={() => { setModalBox(false); resetInput() }}
              ></button>
            </div>
            <div className="modal-body">
              <form
                onSubmit={(e) => {
                  submitActivity(e)
                }}
              >
                <div className="row">
                  <div className="d-flex flex-column gap-3 mb-3 ">
                    <div className="form-group">
                      <label>Plat Kendaraan</label>
                      <input className="form-control" placeholder="BL 1234 ABC" value={plat} onChange={(e) => setPlat(e.target.value)} />
                      <p className={`m-0 ${errorPlat ? 'd-block' : 'd-none'} text-danger`}>Mohon mengisi tipe kendaraan</p>
                    </div>
                    <div className="form-group">
                      <label>Jenis Kendaraan</label>
                      <div className="d-flex gap-3">
                        <button className={`btn ${type === 'Motor' ? 'btn-success' : 'btn-outline-success'}`} onClick={(e) => handleType(e, 'Motor')}>Motor</button>
                        <button className={`btn ${type === 'Rawat Inap Motor' ? 'btn-success' : 'btn-outline-success'}`} onClick={(e) => handleType(e, 'Rawat Inap Motor')}>Rawat Inap Motor</button>
                        <button className={`btn ${type === 'Mobil' ? 'btn-success' : 'btn-outline-success'}`} onClick={(e) => handleType(e, 'Mobil')}>Mobil</button>
                        <button className={`btn ${type === 'Rawat Inap Mobil' ? 'btn-success' : 'btn-outline-success'}`} onClick={(e) => handleType(e, 'Rawat Inap Mobil')}>Rawat Inap Mobil</button>
                      </div>
                      <p className={`m-0 ${errorType ? 'd-block' : 'd-none'} text-danger`}>Mohon mengisi tipe kendaraan</p>
                    </div>
                    {
                      type &&
                      <div className="form-group">
                        <label>Tarif</label>
                        <input className="form-control" disabled value={tarif} />
                      </div>
                    }

                  </div>
                </div>
                <button type="submit" className="btn btn-primary btn-block">
                  Buat Karcis
                </button>
              </form>
            </div>
          </div>
        </>
      </Modal>
    </>
  );
});
export default BasicModal;
