import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import {
	loadingToggleAction, loginAction,
} from '../../store/actions/AuthActions';

// image

import LoginBg from "../../images/login-bg.png";
import LoginBg2 from "../../images/login-bg2.png";
import Logo from "../../images/logo.png";
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import "../index.css";

export const Login = () => {

	const [username, setUsername] = useState('');
	let errorsObj = { username: '', password: '' };
	const [errors, setErrors] = useState(errorsObj);
	const [password, setPassword] = useState('');

	const dispatch = useDispatch();
	const navigate = useNavigate()
	const onLogin = async (e) => {
		e.preventDefault();
		let error = false;
		const errorObj = { ...errorsObj };
		if (username === '') {
			errorObj.username = 'Username/No Ponsel tidak dapat kosong';
			error = true;
		}
		if (password === '') {
			errorObj.password = 'Password tidak dapat kosong';
			error = true;
		}
		setErrors(errorObj);
		if (error) {
			return;
		}
		dispatch(loadingToggleAction(true));
		dispatch(loginAction(username, password, navigate));
	};

	const element = document.querySelector("body");
	let dataTheme = element.getAttribute("data-theme-version");

	return (
		<div className="container-fluid min-vh-100 d-flex align-items-stretch bg-white">
			<div className="row flex-grow-1 w-100 p-3">
				{/* Logo and Text */}
				<div className='position-absolute d-flex gap-3 align-items-center' style={{ top: '20px', left: '20px' }}>
					<img src={Logo} className="logo-dark"
						alt="logo"
						height="40"
					/>
				</div>
				{/* Left Side Form */}
				<div className="col-md-6 d-flex flex-column justify-content-center align-items-center p-5">
					<form style={{ maxWidth: '400px', width: '100%' }} onSubmit={(e) => onLogin(e)}>
						<div className=" mb-4">
							<h2 className='text-gray'>Masuk</h2>
						</div>
						<div className="mb-3">
							<input
								type="text"
								className="form-control"
								id="Username"
								placeholder="Username atau nomor ponsel"
								onChange={(e) => setUsername(e.target.value)}
							/>
						</div>
						<p className={`m-0 mb-3 ${errors.username ? 'd-block' : 'd-none'} text-danger`}>{errors.username}</p>
						<div className="mb-3">
							<input
								type="password"
								className="form-control"
								id="password"
								placeholder="Kata sandi"
								onChange={(e) => setPassword(e.target.value)}
							/>
						</div>
						<p className={`m-0 ${errors.password ? 'd-block' : 'd-none'} text-danger`}>{errors.password}</p>
						<button type="submit" className="btn btn-primary w-100 mt-3">
							Masuk
						</button>

						<div className=" mt-3">
							{/* <span>Lupa kata sandi? </span><a href="/" className='text-secondary'> Klik disini</a> */}
						</div>
					</form>
				</div>

				{/* Right Side Banner */}
				<div className='col-md-6 p-0 ps-5'>
					<div id="parent" className=" rounded-lg " style={{
						background: 'linear-gradient(315deg, #445AD6 2.17%, #7387F7 71.4%)',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '100vh' // Ensure parent takes full vertical height of the viewport
					}}>
						<div id="bg" style={{
							backgroundImage: `url(${LoginBg})`,
							backgroundSize: 'cover',
							backgroundPosition: 'center',
							backgroundRepeat: 'no-repeat',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'end',
							width: '100%',
							height: '100%'
						}}>
							<img
								src={LoginBg2}
								alt="Parking Management"
								className="img-fluid mb-5"
								style={{
									width: '70%',
									height: '50%',
								}}
							/>
						</div>
					</div>
				</div>


			</div>
		</div>
	);
};

export default Login;
