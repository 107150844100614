import React, { useContext } from "react";

/// React router dom
import { Routes, Route, Outlet } from "react-router-dom";

/// Css
import "./index.css";
// import "./chart.css";
// import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Nav2 from "./layouts/nav/index2";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";
/// Dashboard
import Home from "./components/Dashboard/Home";
import Schedule from "./components/Schedule/Rekaman";
import Monitoring from "./components/monitoring";
import UserManagement from "./components/UserManagement/UserManagement";

/// Pages
import Setting from "./layouts/Setting";
import { ThemeContext } from "../context/ThemeContext";
import EditProfile from "./components/AppProfile/EditProfile";
import Tarif from "./components/Tarif/Tarif";

const Markup = () => {
  const adminRoutes = [
    // dashboard
    { url: "admin/dashboard", component: <Home /> },
    /// monitoring
    { url: "admin/monitoring", component: <Monitoring /> },
    /// user-group
    { url: "admin/user-group", component: <UserManagement /> },
    /// profile
    { url: "admin/profile", component: <EditProfile /> },
    { url: "admin/tarif", component: <Tarif /> },
  ];
  const operatorRoutes = [
    // dashboard
    { url: "operator/dashboard", component: <Home /> },
  ];
  return (
    <>
      <Routes>
        <Route element={<Layout7 />}>
          {adminRoutes.map((data, i) => (
            <Route key={i} exact path={`/${data.url}`} element={data.component} />
          ))}
        </Route>
      </Routes>
      <Routes>
        <Route element={<Layout7 />}>
          {operatorRoutes.map((data, i) => (
            <Route key={i} exact path={`/${data.url}`} element={data.component} />
          ))}
        </Route>
      </Routes>
      <Setting />
      <ScrollToTop />
    </>
  );
};

function Layout7() {
  const { menuToggle, sidebariconHover } = useContext(ThemeContext);
  return (
    <div
      id="main-wrapper"
      className={`show ${sidebariconHover ? "iconhover-toggle" : ""} ${menuToggle ? "menu-toggle" : ""}`}
    >
      <Nav />
      <div className="content-body" style={{ minHeight: window.screen.height + 20 }}>
        <div className="container-fluid">
          <Outlet />
        </div>
      </div>
      <Footer changeFooter="out-footer" />
    </div>
  );
}
export default Markup;
