import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import user from './../../../images/user.jpg';
import { useDispatch } from 'react-redux';
import { getPostsAction, updatePostAction } from '../../../store/actions/PostActions';
import Swal from 'sweetalert2';

const inputBlog = [
    { label: 'Name', value: 'John' },
    { label: 'Surname', value: 'Brahim' },
    { label: 'Specialty', value: 'Developer' },
    { label: 'Skills', value: 'HTML,  JavaScript,  PHP' },
];

const EditProfile = () => {
    // const [selectOption , setSelectOption] = useState('Gender');
    const dispatch = useDispatch();
    const [pageData, setPageData] = useState([]);
    const [passwordError, setPasswordError] = useState(false);
    const fetchData = async (username = null) => {
        try {
            const response = await dispatch(getPostsAction('/user/profile'));
            if (response) {
                setPageData(response);
            }
        } catch (error) {
            console.error('Error fetching posts:', error);
        }
    };
    useEffect(() => {
       
        fetchData();
    }, []);
    const onSubmit = async (e) => {
        e.preventDefault();
        setPasswordError(false)
        const [first_name, last_name, username, area, password] = e.target;
        if (password.length < 5) {
            setPasswordError(true)
            return false
        }
        const data = {
            first_name: first_name.value,
            last_name: last_name.value,
            username: username.value,
            area: area.value,
            password: password.value
        }
        const response = await dispatch(updatePostAction('/user/update-profile', data, 0));
        if (response) {
            let userDetails = localStorage.getItem('userDetails');
            if (userDetails) {
                // userDetails = JSON.parse(userDetails);
                // userDetails.displayName = first_name;
                // localStorage.setItem('userDetails', JSON.stringify(userDetails));
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Data Tersimpan",
                    showConfirmButton: false,
                    timer: 1500
                  });
            }
            fetchData();
        }
    }
    return (
        <>
            <div className="row">
                <div className="col-xl-3 col-lg-4">
                    <div className="clearfix">
                        <div className="card card-bx profile-card author-profile m-b30">
                            <div className="card-body">
                                <div className="p-5">
                                    <div className="author-profile">
                                        <div className="author-media">
                                            <img src={user} alt="" />
                                            <div className="upload-link" title="" data-toggle="tooltip" data-placement="right" data-original-title="update">
                                                <input type="file" className="update-flie" />
                                                <i className="fa fa-camera"></i>
                                            </div>
                                        </div>
                                        <div className="author-info">
                                            <h6 className="title">{pageData.first_name + ' ' + pageData.last_name}</h6>
                                            <span>{pageData.area}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-9 col-lg-8">
                    <div className="card profile-card card-bx m-b30">
                        <div className="card-header">
                            <h6 className="title">Account setup</h6>
                        </div>
                        <form className="profile-form"
                            onSubmit={(e) => onSubmit(e)}
                        >
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-6 m-b30" >
                                        <label className="form-label">Nama Depan *</label>
                                        <input type="text" className="form-control" defaultValue={pageData.first_name} />
                                    </div>
                                    <div className="col-sm-6 m-b30" >
                                        <label className="form-label">Nama belakang</label>
                                        <input type="text" className="form-control" defaultValue={pageData.last_name} />
                                    </div>
                                    <div className="col-sm-6 m-b30" >
                                        <label className="form-label">Username/No Handphone *</label>
                                        <input type="text" className="form-control" defaultValue={pageData.username} />
                                    </div>
                                    <div className="col-sm-6 m-b30" >
                                        <label className="form-label">Area *</label>
                                        <input type="text" className="form-control" defaultValue={pageData.area} />
                                    </div>
                                    <div className="col-sm-6 m-b30" >
                                        <label className="form-label">Password</label>
                                        {passwordError && <span className='text-danger'>Password minimal terdiri dari 6 huruf</span>}
                                        <input
                                            type='password'
                                            className="form-control"
                                            placeholder=""
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                <button className="btn btn-primary">Simpan</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
export default EditProfile;
