import React, { useState, useContext, useEffect, useReducer } from 'react';
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";

//import DonutChart from './Dashboard/DonutChart';
//import DonutChart2 from './Dashboard/DonutChart2';

//Import Components
import { ThemeContext } from "../../../context/ThemeContext";
import Activities from './Activities';

//images
//import small1 from './../../../images/profile/small/pic1.jpg';

const Tarif = () => {
	const { changeBackground } = useContext(ThemeContext);
	useEffect(() => {
		changeBackground({ value: "light", label: "Light" });
	}, []);
	return (
		<>
			<div className="row">
				<div className="col-xl-12">
					<div className="row">
						<div className="col-xl-12">
							<div className="page-titles style1">
								<div className="align-items-center">
									<h2 className="heading">Tarif</h2>
									<span>Data tarif parkir</span>
								</div>
							</div>
						</div>
					</div>
					<div className="row bg-white py-4 mx-1 rounded-lg">
						<Activities />
					</div>
				
				</div>
			</div>
		</>
	)
}
export default Tarif;