export const MenuList = [
    //Dashboard
    {
        title:'Dashboard',
        //classsChange: 'mm-collapse',
        iconStyle: <i className="material-icons-outlined">dashboard</i>,
        to: 'admin/dashboard',
    },
   //Dashboard
   {
        title:'Tarif',
        //classsChange: 'mm-collapse',
        iconStyle:<i className="material-icons-outlined">money</i>,
        to: 'admin/tarif',
    },
    //User Management
    {
        title:'Profile',
        //classsChange: 'mm-collapse',
        iconStyle: <i className="material-icons-outlined">person</i>,
        to: 'admin/profile',
    },
     //User Group
     {
        title:'User',
        //classsChange: 'mm-collapse',
        iconStyle: <i className="material-icons-outlined">group</i>,
        to: 'admin/user-group',
    },
     //Monitoring
     {
        title:'Monitoring Alat',
        //classsChange: 'mm-collapse',
        iconStyle: <i className="material-icons-outlined">desktop_windows</i>,
        to: 'admin/monitoring',
    },
       //Schedule
       {
        title:'Akses Rekaman',
        //classsChange: 'mm-collapse',
        iconStyle: <i className="material-icons-outlined">calendar_today</i>,
        to: 'admin/schedule',
    },
    //Web Management
  
]