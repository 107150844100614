import axiosInstance from '../services/AxiosInstance';

export function getPosts(point, param = null) {
    return axiosInstance.get(point, param);
}

export function createPost(point, postData) {
    return axiosInstance.post(point, postData);
}

export function createPostFile(point, postData) {
    return axiosInstance.post(point, postData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
}

export function updatePost(point, post, postId) {
    return axiosInstance.put(`${point}/${postId}`, post);
}

export function deletePost(point, postId) {
    return axiosInstance.delete(point + postId);
}

export function formatPosts(postsData) {
    let posts = [];
    for (let key in postsData) {
        posts.push({ ...postsData[key] });
    }

    return posts;
}
