import axios from 'axios';
import { store } from '../store/store';
import config from '../config';
const getToken = () => {
    const state = store.getState();
    return state.auth.auth.idToken;
};

// Create an Axios instance
const axiosInstance = axios.create({
    baseURL: config.apiUrl + 'api',
    headers: {
        'x-access-token': `Bearer ${getToken()}`,
    },
});

// Set up an interceptor to ensure the token is always up to date
axiosInstance.interceptors.request.use((config) => {
    const token = getToken();
    config.headers['x-access-token'] = `Bearer ${token}`;
    return config;
});

export default axiosInstance;
