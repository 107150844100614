import Swal from 'sweetalert2';
import {
    createPost,
    formatPosts,
    getPosts,
    deletePost,
    updatePost,
    createPostFile,
} from '../../services/PostsService';
import {
    CONFIRMED_CREATE_POST_ACTION,
    CONFIRMED_DELETE_POST_ACTION,
    CONFIRMED_EDIT_POST_ACTION,
    CONFIRMED_GET_POSTS,
    RESET_POSTS
} from './PostTypes';

export function deletePostAction(point, postId, alert = null) {
    return async (dispatch, getState) => {
        try {
            let result;
            if (!alert) {
                result= await Swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Delete"
                });
            }else{
                result = alert;
            }
            if (result.isConfirmed) {
                const response = await deletePost(point, postId);
                dispatch(confirmedDeletePostAction(postId));
                await Swal.fire({
                    title: "Deleted!",
                    text: "Your file has been deleted.",
                    icon: "success"
                });
                return response;
            }
            return null;
        } catch (err) {
            console.error("Error deleting post:", err);
            throw err;
        }
    };
}

export function confirmedDeletePostAction(postId) {
    return {
        type: CONFIRMED_DELETE_POST_ACTION,
        payload: postId,
    };
}

export function updatePostAction(point, post, id) {
    return async (dispatch, getState) => {
        try {
            const response = await updatePost(point, post, id)
            if (response) {
                dispatch(confirmedUpdatePostAction(post));
                return response;
            }
        } catch (err) {
            throw err;
        }
    };
}
export function createPostAction(point, postData) {

    return async (dispatch, getState) => {
        try {
            const response = await createPost(point, postData)
            const singlePost = {
                ...postData,
                ...response.data,
            };
            dispatch(confirmedCreatePostAction(singlePost));
            // history.push('/postpage');
            return response;
        } catch (err) {
            throw err;
        }
    };
}

export function createPostFileAction(point, postData) {

    return async (dispatch, getState) => {
        try {
            const response = await createPostFile(point, postData)
            const singlePost = {
                ...postData,
                ...response.data,
            };
            dispatch(confirmedCreatePostAction(singlePost));
            return response;
        } catch (err) {
            throw err;
        }
    };
}

export function getPostsAction(point, param = null) {
    return async (dispatch, getState) => {
        try {
            const response = await getPosts(point, param);
            let posts = response.data.length ? formatPosts(response.data) : response.data;
            dispatch(confirmedGetPostsAction(posts));
            return posts;
        } catch (err) {
            throw err;
        }
    };
}

export function confirmedCreatePostAction(singlePost) {

    return {
        type: CONFIRMED_CREATE_POST_ACTION,
        payload: singlePost,
    };
}

export function confirmedGetPostsAction(posts) {
    return {
        type: CONFIRMED_GET_POSTS,
        payload: posts,
    };
}

export function confirmedUpdatePostAction(post) {
    return {
        type: CONFIRMED_EDIT_POST_ACTION,
        payload: post,
    };
}

export function resetPosts() {
    return {
        type: RESET_POSTS,
    };
}


